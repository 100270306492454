import React, {
  useEffect,
  // useRef,
  useState
} from 'react';
import { Route, Switch, withRouter, } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar/Sidebar";
import User from "../../pages/User/User";
import Dashboard from "../../pages/Dashboard/Dashboard";
import ChangePasswordModal from "./Component/ChangePassword";
import image from '../../pages/Image/image';
import ImageCategory from '../../pages/ImageCategory/ImageCategory';
import messageDetails from '../../pages/MessageCategory/messageDetails';
import imageDetails from '../../pages/ImageCategory/imageDetails';
import Setting from '../../pages/Setting/Setting';

function Layout(props) {
  const [open, setOpen] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  
  const handleClickOutsideTwo = (e) => {
    
      setOpenMobile(false);
    
  };

  const handleClickInsidetwo = () => setOpenMobile(true);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideTwo);
    return () => document.removeEventListener('mousedown', handleClickOutsideTwo);
  });

  const [openModal, setOpenModal] = useState(false);



  return (
    <div className="overflow-hidden h-screen"  >
      <div className="overflow-hidden">
        <Header history={props.history}
          setOpen={setOpen} open={open}
          setOpenMobile={setOpenMobile}
          openMobile={openMobile}
          handleClickInsidetwo={handleClickInsidetwo}
          openModal={openModal} setOpenModal={setOpenModal}

        />
        <div className="flex h-screen overflow-hidden ">
          <Sidebar open={open} setOpen={setOpen}
            setOpenMobile={setOpenMobile}
            openMobile={openMobile}
            handleClickInsidetwo={handleClickInsidetwo} />
          <div className="w-full  overflow-y-auto bg-[#f3f3f4]  h-[calc(100%-4rem)] ">
            <Switch>
              <Route path="/app/user" component={withRouter(User)} />
              <Route path="/app/dashboard" component={withRouter(Dashboard)} />
              <Route path="/app/image" component={withRouter(image)} />
              <Route path="/app/imageCategory" component={withRouter(ImageCategory)} />
              <Route path="/app/Setting" component={withRouter(Setting)} />
              <Route path="/app/messageDetails/:id" component={withRouter(messageDetails)} />
              <Route path="/app/imageDetails/:name/:id" component={withRouter(imageDetails)} />
            </Switch>
          </div>
        </div>
      </div>

      <ChangePasswordModal openModal={openModal} setOpenModal={setOpenModal} />

    </div>
  );
}

export default withRouter(Layout);
