import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { MdOutlineMenu } from 'react-icons/md';
import { FiChevronDown } from 'react-icons/fi';
import {
  BiPowerOff,
} from 'react-icons/bi';



const Header = (props) => {
  const onClickMenutwo = (url) => {
    localStorage.removeItem('user');
    props.history.push(url)
  }
  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  const handleClickOutside = e => {
    if (!myRef.current.contains(e.target)) {
      setClickedOutside(false);
    }
  };

  const handleClickInside = () => setClickedOutside(true);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });


 
  return (
    <div>
      <div className="bg-[#c1202f] flex items-center justify-between flex-wrap gap-4 md:sticky top-0 z-40 md:px-6 px-2 h-16 relative">

        <div className='flex items-center space-x-4'>
          <button ref={myRef}
            onClick={() => { props.handleClickInsidetwo(); props.setOpen(!props.open); props.setOpenMobile(!props.openMobile); }}
            className='text-white/80 rounded-md '>
            <MdOutlineMenu size={24} />
          </button>
          <div className='xl:w-60 flex justify-start items-center'>
            <p className='text-white'>Flooring Artists Admin</p>
          </div>





        </div>
        <div className='flex items-center md:space-x-6'>
          


          <div className=' text-white cursor-pointer hover:text-white/60 transition duration-150 md:relative  '>
            <div ref={myRef}
              onClick={() => { handleClickInside(); setClickedOutside(!clickedOutside); }}

            >
              <div className='flex items-center space-x-2'>
                <h1 className='select-none text-[.8125rem] '>Admin</h1>
                <FiChevronDown />
              </div>
              {clickedOutside ?
                <div className='animate__animated animate__fadeIn animate__faster absolute md:top-10 top-16 mt-0.5  right-0  shadow-2xl  bg-white  py-2 md:rounded-none rounded-md md:w-48 w-[96%] md:mx-0 mr-[2%] ml-[2%] text-sm text-slate-800 border transition duration-150 z-40'>

                  <button onClick={() => onClickMenutwo("/login")} className='px-6  py-2 hover:bg-slate-100 w-full flex items-center border-t text-xs text-red-700'> <BiPowerOff className='mr-3' size={16} /> Logout</button>
                </div>

                : null}
            </div>

          </div>
        </div>

      </div>



    </div>


  );

}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(Header);