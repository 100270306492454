import React from "react";
import { CgLogIn } from 'react-icons/cg';


export default function UpdateCategoryModal(props) {

  let { UpdateCategoryCreateModal, handleAppSeetingHideModal, errorsUpdateValet, inputChangeUpdateCategory, updateCategorySubmit, fieldsSettingUpdate, filesDetails, handleFile } = props;
  // console.log("MODAL__fieldsValetUpdate::", fieldsValetUpdate);


  return (

    <div className={UpdateCategoryCreateModal ? "fixed w-full top-0 left-0 h-full inset-0 z-40 overflow-hidden mt-0 flex justify-center items-center overflow-y-auto bg-black/40 md:p-0 p-1" : "hidden"} style={{ marginTop: '0rem', }}  >
    <div className="animate__animated animate__fadeInDown animate__faster bg-[#f8f8fb]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto mt-2">
      {/*modal header*/}
      <div className="flex items-center justify-between p-6 py-2 bg-[#c1202f]  border-b">
        <p className="text-[16px] font-medium text-white uppercase">Update Setting </p>
        <div className="rounded-full cursor-pointer modal-close ">
          <svg onClick={() => handleAppSeetingHideModal()} className="fill-current text-white/60 hover:text-white/100 " xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
            </path>
          </svg>
        </div>
      </div>

      <div className="p-4">
   
        <form autoComplete="off" className="p-4 space-y-4 capitalize border border-gray-300 ">
          

      




          <div className="w-full">
            <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Name : </label>
            <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdateValet && !errorsUpdateValet["name"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
              id="name" name="name" placeholder="Name" value={fieldsSettingUpdate && fieldsSettingUpdate.name ? fieldsSettingUpdate.name : ''} type="text" onChange={inputChangeUpdateCategory} />
            {errorsUpdateValet && errorsUpdateValet["name"] ?
              <div className="text-xs text-red-500 invalid-feedback">
                {errorsUpdateValet["name"]}
              </div>
              : null}
          </div>

          <div className="w-full">
            <label className="block text-xs font-medium text-gray-500 md:text-left" for="username">Key : </label>
            <input className={` w-full p-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 ring-gray-500  ${errorsUpdateValet && !errorsUpdateValet["key"] ? "border placeholder-gray-500" : "border border-red-500 "}`}
              id="key" name="key" placeholder="key" value={fieldsSettingUpdate && fieldsSettingUpdate.key ? fieldsSettingUpdate.key : ''} type="text" onChange={inputChangeUpdateCategory} />
            {errorsUpdateValet && errorsUpdateValet["key"] ?
              <div className="text-xs text-red-500 invalid-feedback">
                {errorsUpdateValet["key"]}
              </div>
              : null}
          </div>


        

          <div className="flex justify-between px-2"   >
            <div className="flex flex-wrap w-full py-2">
              <label htmlFor="image">
                Upload Image 
              </label>
              <input id="value" name="value" type="file"  onChange={handleFile} />
              {/* <input multiple  id="image" name="image" type="file" onChange={handleFile} /> */}
            </div>


            {
              filesDetails && filesDetails.imageURL ?
                <>
                  <div>
                    <div className='flex justify-center'>
                      <img className="object-cover h-14 rounded-sm w-14" src={filesDetails && filesDetails.imageURL ? filesDetails.imageURL : null} alt="not found" />
                    </div>
                  </div>
                </> :
                <>
                  <div>
                    <div className='flex justify-center'>
                      <img className="object-cover h-14 rounded-sm w-16" src="/images/profile.png" alt="not found" />
                    </div>
                  </div>
                </>
            } 





          </div>

          {/* btns */}
          <div className="flex justify-end py-0 space-x-4">
            <button className="bg-[#c1202f]   transition duration-150 text-white text-[.8125rem]  px-3 py-2 rounded-[.25rem] flex items-center hover:bg-[#f14646] " type="button"
              onClick={updateCategorySubmit}
            >Submit
              <CgLogIn size={18} className="ml-2" />
            </button>
          </div>

        </form>
      </div>
      {/*Footer*/}
    </div>
  </div>


















    // <Modal
    //   isOpen={UpdateCategoryCreateModal}
    // // contentLabel="Example Modal"
    // >


    //   <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
    //     <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
    //       <div className="px-6 py-4 text-left modal-content">
    //         {/*Title*/}
    //         <div className="flex items-center justify-between">
    //           <p className="text-2xl font-bold">Update Ticket Status</p>
    //           <div className="z-50 cursor-pointer modal-close">
    //             <svg onClick={() => handleAppSeetingHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
    //               <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
    //               </path>
    //             </svg>
    //           </div>
    //         </div>
    //         {/*Body*/}
    //         <div className="">
    //           <form autoComplete="off">





    //             <div className="{otpSent?'disableArea':''}">
    //               <div className="relative mt-1 shadow-sm">
    //                 <label className="mt-2 mr-2 text-sm font-medium text-black">Reply Message:</label>
    //                 <input className={`border-1 px-3 py-2 placeholder-blueGray-400 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${errorsUpdateValet && !errorsUpdateValet["reply"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
    //                   id="reply" name="reply" placeholder="Reply" value={fieldsValetUpdate.reply} type="reply" onChange={inputChangeUpdateCategory} />
    //                 {errorsUpdateValet && errorsUpdateValet["reply"] ?
    //                   <div className="text-yellow-600 invalid-feedback">
    //                     {errorsUpdateValet["reply"]}
    //                   </div>
    //                   : null}
    //               </div>
    //             </div>


    //             <div className="mx-auto mt-6 w-60">
    //               <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-blue-500 border border-blue-500 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-blue-500 focus:shadow-outline-yellow active:bg-blue-500" type="button" onClick={updateCategorySubmit}>Submit</button>
    //             </div>
    //           </form>

    //         </div>
    //         {/*Footer*/}
    //       </div>
    //     </div>
    //   </div >


    // </Modal >



  );
}
